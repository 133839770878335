import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";

const NotFoundPage = () => (
  <Layout>
    <Seo
      title="Page Not Found"
    />

    <div className="relative z-0" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.5",
          height: 800,
        }}
        alt="Contact Us"
        src="../img/slider1.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <h2 className="text-white text-7xl font-extrabold text-center">Oops!</h2>
          <p className="text-white text-4xl font-semibold text-center mt-12">
            We can't seem to find the page.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage